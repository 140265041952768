/*---------------------------------------------*/

.card-template {
	text-align: center;
	grid-template-rows: 65px 1fr auto var(--space-stack-l);
	height: 100%;
	display: grid;

	.logo {
		margin-bottom: 32px;
	}

	.separator {
		width: 28px;
		height: 24px;
		position: relative;
		vertical-align: top;
		display: inline-block;

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);

			content: '';
			width: 6px;
			height: 6px;
			margin-top: -1px;
			border-radius: 100px;
			background: var(--color-neutral-50);
		}
	}

	.card {
		width: 448px;
		padding: 32px 80px;
		margin: auto;
		box-sizing: border-box;
	
		background: var(--color-neutral-10);
		box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
	}
	
	.footer {
		color: var(--color-neutral-70);
	}
}

