#root {
	--color-primary-50:  #F5F9FF;
	--color-primary-100: #EBF3FF;
	--color-primary-200: #DBE9FF;
	--color-primary-300: #C2DAFF;
	--color-primary-400: #8FBBFF;
	--color-primary-500: #5C9CFF;
	--color-primary-600: #287DFF;
	--color-primary-700: #1B69E0;
	--color-primary-800: #0C53C2;
	--color-primary-900: #0040A3;
}