@import './colors';
@import './designSystem';

/*---------------------------------------------*/

html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--color-neutral-50);
	color: var(--color-neutral-900);
	overflow-y: scroll;
}

#root {
	height: 100%;
	display: grid;
	grid-template-rows: min-content 1fr;
	// align-items: start;
}

/*---------------------------------------------*/

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	color: inherit;
	text-decoration: none;
}

.card {
	background: white;
	min-height: 400px;
}

.loading {
	display: inline-block;
	margin: auto;
	left: 50%;
	padding: 8px 32px;
	background: var(--color-warning-100);
	border-radius: 3px;
	color: var(--color-neutral-800);
}

.lth-l-grid {
	height: auto !important;

	.lth-l-grid__container {
		position: static;
	}
}

/*---------------------------------------------*/

#header {
	background-color: white;
	// color: white;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
	position: relative;
	z-index: 1;
	text-align: center;

	.inner {
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;
		padding: 16px;
	}
	
	a {
		xcolor: inherit;
		justify-self: start;
	}
	
	.menu {
		display: inline-block;
		vertical-align: middle;
		margin-left: 16px;
	}
	
	.menu p {
		display: inline-block;
		margin-left: 24px;
		cursor: pointer;
	}

	.lth-l-grid {
		height: 64px !important;
	}
}


.inner {
	width: 1504px;
	margin: auto;
	position: relative;
}

.main {
	text-align: center;
	position: relative;

	.content {

	}
}

/*---------------------------------------------*/

.sites {
}

.site {
	background: #FFFFFF;
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.06);
	border-radius: 2px;
	padding: 24px 32px;
}
 
.site-header {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	padding: 4px 0;
}

.site-header .actions > * {
	/* the default vertical-align=baseline creates some extra space on top */
	vertical-align: top;
}

.table {
	.table--row {
		display: grid;
		padding: 12px 0;
		position: relative;
		justify-items: left;
	
		&.table--header { 
			text-transform: uppercase;
			font-size: 12px;
			line-height: 16px;
			color: var(--color-neutral-700);
			font-weight: bold;
			// font-family: Roboto;
		}

		&:first-child + .table--row {
			padding-top: 16px;
			border-top: 2px solid var(--color-neutral-300);
		}

		&:last-child {
			padding-bottom: 16px;
			border-bottom: 2px solid var(--color-neutral-300);
		}
	}
}

hr {
	border: 0;
	border-bottom: 1px solid #eaeaea;
}

hr.fullwidth {
	margin-left: -50px;
	margin-right: -50px;
}

.right-aligned {
	text-align: right;
	font-size: 0;
}

/*---------------------------------------------*/

.dashboard {
	.main {
		display: grid;
		grid-template-columns: 250px auto;
		grid-gap: 12px;
		margin-top: 32px;
	}
	
	.menu {
		padding-right: 8px;
		padding-left: 16px;
		box-sizing: border-box;
		height: 100%;
		min-height: calc(100vh - 64px);
		box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.08);
	}
	
	.menu ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	
	.menu li {
		font-size: 19px;
	}
	
	.menu li a {
		display: block;
		font-size: 16px;
		padding: 8px 12px;
		border-radius: 2px;
	
		&:first-child {
			padding: 8px 12px 8px 16px;
		}
	}
	
	.menu li a.selected {
		background: var(--color-primary-50);
		border-left: 4px solid var(--color-primary-600);
		padding-left: 12px;
		font-weight: bold;
	}
	
	.menu li a:hover:not(.selected) {
		background: var(--color-primary-50);
	}
	
	.menu .icon {
		width: 20px;
		margin-right: 8px;
		margin-top: 4px;
	}
	
	.content {
		padding: 32px 0 5em 24px;
		height: 100%;
		box-sizing: border-box;
		position: relative;
	}
}

/*---------------------------------------------*/

.section-header {
	display: inline-block;
	font-weight: bold;
	font-size: 12px;
	line-height: 27px;
	color: var(--color-primary-900);
	letter-spacing: 0.4px;
	border-bottom: 2px solid var(--color-primary-900);
	text-transform: uppercase;
}

/*---------------------------------------------*/

.fb-button {
	background: #1877f2;
	border-radius: 4px;
	display: inline-grid;
	position: relative;
	grid-template-columns: min-content auto;
	align-items: center;
	overflow: hidden;
	cursor: pointer;

	&:active:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		background-color: rgba(9, 30, 66, 0.3);
	}

	.fb-button__image {
		width: 24px;
		height: 24px;
		margin: 8px;
		margin-left: 12px;
	}

	.fb-button__text {
		color: white;
		font-family: Helvetica, Arial, sans-serif;
		font-size: 16px;
		font-weight: 700;
		margin-right: 12px;
		margin-top: 1px;
	}
}

/*---------------------------------------------*/

.badge {
	display: inline-block;
	background: var(--color-primary-100);
	border-radius: 100px;
	padding: 0 16px;
	line-height: 24px;
}

/*---------------------------------------------*/
