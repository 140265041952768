/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
.likepopup-container {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  /* override wix styling */
  /* http://stackoverflow.com/a/4407335 */
  /*--------------------------------*/
  /*--------------------------------*/
  /*--------------------------------*/
  /*--------------------------------*/ }
  .likepopup-container .title {
    font: inherit; }
  .likepopup-container h2,
  .likepopup-container h3 {
    margin: 0;
    padding: 0;
    overflow: hidden;
    word-wrap: break-word; }
  .likepopup-container table {
    border-spacing: 0px;
    border-collapse: separate;
    font: inherit;
    color: inherit; }
  .likepopup-container tr {
    vertical-align: top; }
  .likepopup-container td {
    padding: 0px; }
  .likepopup-container .unselectable {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .likepopup-container a {
    cursor: pointer;
    text-decoration: none;
    color: #3b5999; }
  .likepopup-container a:hover {
    text-decoration: underline; }
  .likepopup-container hr {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .likepopup-container button {
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    font: inherit;
    color: inherit;
    padding: 0; }
  .likepopup-container .center-aligned {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%); }
  .likepopup-container .template-container {
    border-radius: inherit;
    background: transparent; }
  .likepopup-container #promotion {
    font-weight: 400;
    color: #888;
    background: #f8f9fc;
    margin: 16px auto;
    position: relative;
    text-align: center;
    /* force parent reserve space for the promotion margin */
    display: inline-block;
    white-space: nowrap;
    padding: 6px 22px 8px;
    width: 90%;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    font-family: Open Sans;
    font-size: 13px;
    color: #999; }
  .likepopup-container #promotion:xbefore {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: -18px;
    left: 0;
    border-top: 1px solid #bbb; }
  .likepopup-container #promotion a {
    text-decoration: none;
    font-weight: 600;
    color: #999; }
  .likepopup-container #promotion a:hover {
    color: #707070; }
  .likepopup-container .fb-like {
    min-width: 49px;
    min-height: 20px;
    display: inline-block;
    overflow: hidden; }
  .likepopup-container #like-with-details {
    white-space: nowrap;
    min-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .likepopup-container #like-with-details > div > span {
      height: unset !important; }
  .likepopup-container #like-with-details .fb-like {
    margin-right: 3px;
    vertical-align: top;
    display: flex; }
  .likepopup-container #like-with-details .details {
    display: inline-block;
    vertical-align: top; }
  .likepopup-container .details {
    min-height: 1em; }
  .likepopup-container #close {
    position: absolute;
    top: 16px;
    right: 14px;
    cursor: pointer;
    z-index: 9999;
    line-height: 0; }
  .likepopup-container #users-gallery {
    --column-gap: var(--avatar-column-gap,4px);
    --row-gap: var(--avatar-row-gap,12px);
    display: grid;
    grid-template-columns: repeat(var(--avatar-columns), var(--avatar-size));
    -moz-column-gap: var(--column-gap);
         column-gap: var(--column-gap);
    row-gap: var(--row-gap);
    overflow: hidden;
    height: calc(calc(var(--avatar-rows, 2) * calc(var(--avatar-size) + var(--row-gap))) - var(--row-gap));
    justify-content: center; }
    .likepopup-container #users-gallery a img {
      width: var(--avatar-size);
      height: calc(calc(var(--avatar-size) * 1) / 1); }
    .likepopup-container #users-gallery a[href=''] {
      pointer-events: none; }

/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@import url("https://fonts.googleapis.com/css2?family=Enriqueta:wght@400;700&display=swap");
@media screen and (min-width: 0px) and (max-width: 480px) {
  #template-1:not([class*='responsive-override']) {
    --container-padding-vertical: 48px;
    --subtitle-font-size: 18px;
    --subtitle-line-height: 32px;
    --details-margin-top: 50px;
    --like-with-details-text-font-size: 12px;
    --avatar-size: 32px;
    --avatar-rows: 1;
    --container-width: 280px;
    --title-font-size: 32px;
    --title-line-height: 40px;
    --container-padding-horizontal: 12px;
    --avatar-columns: 5; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #template-1:not([class*='responsive-override']) {
    --container-padding-vertical: 48px;
    --subtitle-font-size: 18px;
    --subtitle-line-height: 32px;
    --details-margin-top: 50px;
    --like-with-details-text-font-size: 12px;
    --avatar-size: 32px;
    --avatar-rows: 1;
    --container-width: 320px;
    --title-font-size: 32px;
    --title-line-height: 40px;
    --container-padding-horizontal: 32px;
    --avatar-columns: 5; } }

@media screen and (min-width: 640px) and (max-width: 1024px) {
  #template-1:not([class*='responsive-override']) {
    --container-padding-vertical: 48px;
    --subtitle-font-size: 18px;
    --subtitle-line-height: 32px;
    --details-margin-top: 50px;
    --like-with-details-text-font-size: 12px;
    --avatar-size: 32px;
    --avatar-rows: 1;
    --container-width: 425px;
    --title-font-size: 32px;
    --title-line-height: 40px;
    --container-padding-horizontal: 66px;
    --avatar-columns: 7; } }

@media screen and (min-width: 1024px) and (max-width: 999999999px) {
  #template-1:not([class*='responsive-override']) {
    --container-padding-vertical: 40px;
    --subtitle-font-size: 18px;
    --subtitle-line-height: 32px;
    --details-margin-top: 50px;
    --like-with-details-text-font-size: 12px;
    --avatar-size: 32px;
    --avatar-rows: 1;
    --container-width: 425px;
    --title-font-size: 36px;
    --title-line-height: 48px;
    --container-padding-horizontal: 32px;
    --avatar-columns: 9; } }

#template-1.breakpoint-desktop {
  --container-width: 425px !important;
  --title-font-size: 36px !important;
  --title-line-height: 48px !important;
  --container-padding-vertical: 40px !important;
  --container-padding-horizontal: 32px !important;
  --like-with-details-text-font-size: 12px !important;
  --avatar-columns: 9 !important; }

#template-1.likepopup-container {
  width: var(--container-width);
  background: white;
  padding: var(--container-padding-vertical) var(--container-padding-horizontal) 0; }

#template-1 #close img {
  width: 16px; }

#template-1 h2 {
  font-family: 'Enriqueta', serif;
  font-size: var(--title-font-size);
  font-weight: normal;
  line-height: var(--title-line-height);
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.01);
  /* antialiasing for safari 5 */ }

#template-1 h3 {
  font-family: 'Enriqueta', serif;
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
  font-weight: normal;
  margin-top: 1em;
  color: rgba(0, 0, 0, 0.5);
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.01);
  /* antialiasing for safari 5 */ }

#template-1 #users-gallery img {
  width: var(--avatar-size);
  height: var(--avatar-size); }

#template-1 #like-with-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--details-margin-top) auto 16px; }
  #template-1 #like-with-details div.fb-like > span {
    height: 20px !important; }
  #template-1 #like-with-details .details {
    font-size: var(--like-with-details-text-font-size);
    margin-top: 0; }

#template-1 hr {
  margin-top: 20px;
  border-color: rgba(0, 0, 0, 0.2); }

/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&display=swap");
@media screen and (min-width: 0px) and (max-width: 480px) {
  #template-2:not([class*='responsive-override']) {
    --title-font-size: 28px;
    --title-line-height: 36px;
    --thumb-margin-top: 40px;
    --thumb-size: 72px;
    --bottom-box-height: 68px;
    --container-width: 280px; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #template-2:not([class*='responsive-override']) {
    --title-font-size: 28px;
    --title-line-height: 36px;
    --thumb-margin-top: 40px;
    --thumb-size: 88px;
    --bottom-box-height: 68px;
    --container-width: 360px; } }

@media screen and (min-width: 640px) and (max-width: 1024px) {
  #template-2:not([class*='responsive-override']) {
    --title-font-size: 32px;
    --title-line-height: 40px;
    --thumb-margin-top: 56px;
    --thumb-size: 88px;
    --bottom-box-height: 100px;
    --container-width: 460px; } }

@media screen and (min-width: 1024px) and (max-width: 999999999px) {
  #template-2:not([class*='responsive-override']) {
    --title-font-size: 36px;
    --title-line-height: 46px;
    --thumb-margin-top: 56px;
    --thumb-size: 106px;
    --bottom-box-height: 120px;
    --container-width: 500px; } }

#template-2.breakpoint-desktop {
  --container-width: 500px !important;
  --thumb-margin-top: 56px !important;
  --thumb-size: 106px !important;
  --title-font-size: 36px !important;
  --title-line-height: 46px !important;
  --bottom-box-height: 120px !important; }

#template-2.likepopup-container {
  width: var(--container-width);
  background: white;
  border-radius: 8px; }

html:not(._Y9Ecf) #template-2.template-container {
  margin-right: 20px;
  margin-top: 20px; }

#template-2 #close {
  --pad: 12px;
  padding: var(--pad);
  --offset: calc(-1 * var(--pad));
  top: var(--offset);
  right: var(--offset);
  display: inline-block;
  background: white;
  border: 3px solid #3b5999;
  border-radius: 50%; }
  #template-2 #close img {
    display: inline-block;
    border-radius: 50%;
    width: 12px; }

#template-2 #top-box {
  box-sizing: border-box;
  padding-bottom: 25px;
  background: #3b5999;
  color: white;
  position: relative;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  #template-2 #top-box h2 {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 36px;
    font-weight: 700;
    margin: 0 40px 10px 40px;
    line-height: var(--title-line-height);
    font-size: var(--title-font-size); }
  #template-2 #top-box h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding: 0 40px;
    max-height: 2.8em; }
  #template-2 #top-box svg {
    margin-top: var(--thumb-margin-top);
    margin-bottom: 16px;
    width: var(--thumb-size); }

#template-2 #bottom-box {
  position: relative;
  height: var(--bottom-box-height); }

#template-2 #promotion {
  transform: translate(0, -20px);
  -webkit-transform: translate(0, -20px); }

/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@media screen and (min-width: 0px) and (max-width: 480px) {
  #template-3:not([class*='responsive-override']) {
    --container-padding-top: 44px;
    --container-padding-horizontal: 12px;
    --title-font-size: 26px;
    --title-line-height: 36px;
    --subtitle-font-size: 18px;
    --subtitle-line-height: 32px;
    --details-margin-top: 40px;
    --avatar-size: 32px;
    --like-with-details-text-font-size: 12px;
    --container-width: 280px;
    --avatar-columns: 5; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #template-3:not([class*='responsive-override']) {
    --container-padding-top: 44px;
    --container-padding-horizontal: 32px;
    --title-font-size: 28px;
    --title-line-height: 40px;
    --subtitle-font-size: 18px;
    --subtitle-line-height: 32px;
    --details-margin-top: 40px;
    --avatar-size: 32px;
    --like-with-details-text-font-size: 12px;
    --container-width: 320px;
    --avatar-columns: 5; } }

@media screen and (min-width: 640px) and (max-width: 1024px) {
  #template-3:not([class*='responsive-override']) {
    --container-padding-top: 44px;
    --container-padding-horizontal: 40px;
    --title-font-size: 28px;
    --title-line-height: 40px;
    --subtitle-font-size: 18px;
    --subtitle-line-height: 32px;
    --details-margin-top: 40px;
    --avatar-size: 32px;
    --like-with-details-text-font-size: 16px;
    --avatar-columns: 7; } }

@media screen and (min-width: 1024px) and (max-width: 999999999px) {
  #template-3:not([class*='responsive-override']) {
    --container-padding-top: 48px;
    --container-padding-horizontal: 62px;
    --title-font-size: 28px;
    --title-line-height: 40px;
    --subtitle-font-size: 18px;
    --subtitle-line-height: 32px;
    --details-margin-top: 40px;
    --avatar-size: 32px;
    --like-with-details-text-font-size: 16px;
    --container-width: 420px;
    --container-padding-bottom: 80px;
    --avatar-columns: 8; } }

#template-3.breakpoint-desktop {
  --container-width: 420px !important;
  --like-with-details-text-font-size: 16px !important;
  --container-padding-top: 48px !important;
  --container-padding-bottom: 80px !important;
  --container-padding-horizontal: 62px !important;
  --avatar-columns: 8 !important; }

#template-3.likepopup-container {
  width: var(--container-width);
  background: white;
  padding: var(--container-padding-top) var(--container-padding-horizontal) 0; }

#template-3 #close img {
  width: 16px; }

#template-3 h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: var(--title-font-size);
  line-height: var(--title-line-height);
  color: #287dff;
  margin-top: 14px;
  margin-bottom: 8px; }

#template-3 h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
  font-weight: normal;
  color: rgba(0, 0, 0, 0.9); }

#template-3 #bottom-box {
  display: inline-block;
  text-align: left;
  margin: auto; }
  #template-3 #bottom-box #like-with-details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--details-margin-top) auto 32px; }
    #template-3 #bottom-box #like-with-details div.fb-like > span {
      height: 20px !important; }
    #template-3 #bottom-box #like-with-details .details {
      font-size: var(--like-with-details-text-font-size);
      margin-top: 0; }

#template-3 hr {
  margin-top: 40px;
  border-color: rgba(0, 0, 0, 0.2); }

/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@media screen and (min-width: 0px) and (max-width: 480px) {
  #template-4:not([class*='responsive-override']) {
    --close-icon-offset: 20px;
    --close-icon-size: 16px;
    --title-font-size: 24px;
    --title-line-height: 24px;
    --details-font-height: 16px;
    --details-line-height: 24px;
    --container-width: 280px;
    --avatar-size: 40px;
    --avatar-columns: 5; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #template-4:not([class*='responsive-override']) {
    --close-icon-offset: 20px;
    --close-icon-size: 16px;
    --title-font-size: 28px;
    --title-line-height: 40px;
    --details-font-height: 16px;
    --details-line-height: 24px;
    --container-width: 340px;
    --avatar-size: 48px;
    --avatar-columns: 5; } }

@media screen and (min-width: 640px) and (max-width: 1024px) {
  #template-4:not([class*='responsive-override']) {
    --close-icon-offset: 20px;
    --close-icon-size: 16px;
    --title-font-size: 28px;
    --title-line-height: 40px;
    --details-font-height: 16px;
    --details-line-height: 24px;
    --container-width: 480px;
    --avatar-size: 48px;
    --avatar-columns: 8; } }

@media screen and (min-width: 1024px) and (max-width: 999999999px) {
  #template-4:not([class*='responsive-override']) {
    --close-icon-offset: 24px;
    --close-icon-size: 20px;
    --title-font-size: 32px;
    --title-line-height: 32px;
    --details-font-height: 16px;
    --details-line-height: 32px;
    --container-width: 600px;
    --avatar-size: 60px;
    --details-font-size: 20px;
    --avatar-columns: 8;
    --avatar-column-gap: 6px;
    --avatar-row-gap: 16px; } }

#template-4.breakpoint-desktop {
  --container-width: 600px !important;
  --avatar-size: 60px !important;
  --title-font-size: 32px !important;
  --title-line-height: 32px !important;
  --close-icon-size: 20px !important;
  --close-icon-offset: 24px !important;
  --details-font-size: 20px !important;
  --details-line-height: 32px !important;
  --avatar-columns: 8 !important;
  --avatar-column-gap: 6px !important;
  --avatar-row-gap: 16px !important; }

#template-4.likepopup-container {
  text-align: left;
  width: var(--container-width);
  background: white;
  padding: 40px 24px 0;
  border-radius: 5px; }

#template-4 #close {
  position: absolute;
  top: var(--close-icon-offset);
  right: var(--close-icon-offset); }
  #template-4 #close img {
    width: var(--close-icon-size); }

#template-4 .header img#profile {
  margin-right: 16px;
  width: var(--avatar-size); }

#template-4 .header h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #287dff;
  margin: 0;
  margin-bottom: 10px;
  font-size: var(--title-font-size);
  line-height: var(--title-line-height); }

#template-4 hr {
  border-color: rgba(0, 0, 0, 0.1);
  margin: 20px 0px 3px 0px; }

#template-4 hr#bottom-hr {
  margin-top: 8px; }

#template-4 .details {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: var(--details-font-height);
  line-height: var(--details-line-height);
  margin: 24px 0;
  display: block; }

#template-4 #users-gallery {
  justify-content: start; }

#template-4 #promotion {
  width: 100%; }

/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@media screen and (min-width: 0px) and (max-width: 480px) {
  #template-5:not([class*='responsive-override']) {
    --content-padding-horizontal: 24px;
    --title-font-size: 24px;
    --title-line-height: 32px;
    --container-width: 280px; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #template-5:not([class*='responsive-override']) {
    --content-padding-horizontal: 24px;
    --title-font-size: 24px;
    --title-line-height: 32px;
    --container-width: 361px; } }

@media screen and (min-width: 640px) and (max-width: 1024px) {
  #template-5:not([class*='responsive-override']) {
    --content-padding-horizontal: 40px;
    --title-font-size: 24px;
    --title-line-height: 32px;
    --container-width: 440px; } }

@media screen and (min-width: 1024px) and (max-width: 999999999px) {
  #template-5:not([class*='responsive-override']) {
    --content-padding-horizontal: 80px;
    --title-font-size: 30px;
    --title-line-height: 38px;
    --container-width: 575px; } }

#template-5.breakpoint-desktop {
  --container-width: 575px !important;
  --title-font-size: 30px !important;
  --title-line-height: 38px !important;
  --content-padding-horizontal: 80px !important; }

#template-5.likepopup-container {
  width: var(--container-width);
  background-color: #fcfafa; }

#template-5 #close {
  right: -12px;
  top: -12px;
  display: inline-block; }

#template-5 #image-box {
  background-size: contain;
  background-position: center;
  width: var(--container-width);
  height: calc(calc(var(--container-width) * 13) / 35); }

#template-5 #content-box {
  padding: 24px var(--content-padding-horizontal) 32px;
  position: relative; }
  #template-5 #content-box h2 {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    font-size: var(--title-font-size);
    line-height: var(--title-line-height);
    color: #2962ff; }
  #template-5 #content-box h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin-top: 16px; }

#template-5 #footer {
  height: 54px;
  background-color: #f2eeee;
  position: relative; }

#template-5 #promotion {
  display: none; }

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@media screen and (min-width: 0px) and (max-width: 480px) {
  #template-6:not([class*='responsive-override']) {
    --image-width: 156px;
    --content-padding-horizontal: 40px;
    --title-font-size: 24px;
    --title-line-height: 32px;
    --container-width: 280px;
    --footer-padding-horizontal: 24px; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #template-6:not([class*='responsive-override']) {
    --image-width: 156px;
    --content-padding-horizontal: 20px;
    --title-font-size: 28px;
    --title-line-height: 36px;
    --container-width: 320px;
    --footer-padding-horizontal: 44px; } }

@media screen and (min-width: 640px) and (max-width: 1024px) {
  #template-6:not([class*='responsive-override']) {
    --image-width: 156px;
    --content-padding-horizontal: 20px;
    --title-font-size: 28px;
    --title-line-height: 36px;
    --container-width: 320px;
    --footer-padding-horizontal: 44px; } }

@media screen and (min-width: 1024px) and (max-width: 999999999px) {
  #template-6:not([class*='responsive-override']) {
    --image-width: 184px;
    --content-padding-horizontal: 40px;
    --title-font-size: 30px;
    --title-line-height: 36px;
    --container-width: 375px;
    --footer-padding-horizontal: 72px; } }

#template-6.breakpoint-desktop {
  --container-width: 375px !important;
  --image-width: 184px !important;
  --title-font-size: 30px !important;
  --content-padding-horizontal: 40px !important;
  --footer-padding-horizontal: 72px !important; }

#template-6.likepopup-container {
  width: var(--container-width);
  background-color: #fefefe; }

#template-6 #close {
  right: 16px;
  top: 16px;
  display: inline-block; }
  #template-6 #close img {
    display: inline-block;
    border-radius: 50%; }

#template-6 #image-box {
  padding-top: 45px; }
  #template-6 #image-box img {
    width: var(--image-width); }

#template-6 #content {
  padding: 0 var(--content-padding-horizontal);
  margin-bottom: 40px; }
  #template-6 #content h2 {
    margin-top: 29px;
    margin-bottom: 29px;
    font-family: 'Roboto Slab', serif;
    font-size: var(--title-font-size);
    font-weight: 400;
    line-height: var(--title-line-height);
    text-align: center;
    color: #4a4a4a; }
  #template-6 #content h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #4a4a4a;
    margin-bottom: 42px;
    min-height: 6px; }

#template-6 #footer {
  height: 60px;
  background-color: #ebe9e9;
  position: relative; }

#template-6 #promotion {
  display: none; }

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@media screen and (min-width: 0px) and (max-width: 480px) {
  #template-7:not([class*='responsive-override']) {
    --close-icon-offset: 12px;
    --content-padding-horizontal: 20px;
    --background-image-width: 120px;
    --title-font-size: 24px;
    --title-line-height: 32px;
    --container-width: 280px; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #template-7:not([class*='responsive-override']) {
    --close-icon-offset: 12px;
    --content-padding-horizontal: 64px;
    --background-image-width: 160px;
    --title-font-size: 26px;
    --title-line-height: 36px;
    --container-width: 400px; } }

@media screen and (min-width: 640px) and (max-width: 1024px) {
  #template-7:not([class*='responsive-override']) {
    --close-icon-offset: 12px;
    --content-padding-horizontal: 64px;
    --background-image-width: 160px;
    --title-font-size: 26px;
    --title-line-height: 36px;
    --container-width: 460px; } }

@media screen and (min-width: 1024px) and (max-width: 999999999px) {
  #template-7:not([class*='responsive-override']) {
    --close-icon-offset: 16px;
    --content-padding-horizontal: 90px;
    --background-image-width: 160px;
    --title-font-size: 32px;
    --title-line-height: 36px;
    --container-width: 580px; } }

#template-7.breakpoint-desktop {
  --container-width: 580px !important;
  --title-font-size: 32px !important;
  --content-padding-horizontal: 90px !important;
  --close-icon-offset: 16px !important; }

#template-7.likepopup-container {
  width: var(--container-width); }

#template-7 #close {
  top: var(--close-icon-offset);
  right: var(--close-icon-offset);
  display: inline-block; }
  #template-7 #close img {
    display: inline-block;
    border-radius: 50%; }

#template-7 #content {
  --background-blue-start: #4475fb;
  --background-blue-end: #2962ff;
  position: relative;
  padding: 36px var(--content-padding-horizontal) 32px;
  background-color: var(--background-blue-start);
  background-image: radial-gradient(207.56% 58.29% at 50% 51.15%, var(--background-blue-start) 0%, var(--background-blue-end) 100%); }
  #template-7 #content .background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: var(--background-image-width);
    background-repeat: repeat-y; }
    #template-7 #content .background-image.left {
      left: 0;
      background-position-x: right; }
    #template-7 #content .background-image.right {
      right: 0; }
  #template-7 #content h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: var(--title-font-size);
    font-weight: 700;
    line-height: var(--title-line-height);
    color: white; }
  #template-7 #content h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: white;
    margin-top: 19px; }

#template-7 #footer {
  height: 60px;
  background-color: #f2eeee;
  position: relative; }

#template-7 #promotion {
  display: none; }

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");
/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@media screen and (min-width: 0px) and (max-width: 480px) {
  #template-8:not([class*='responsive-override']) {
    --background-size: auto;
    --default-pad: 40px;
    --title-font-size: 22px;
    --title-line-height: 32px;
    --subtitle-font-size: 16px;
    --container-width: 280px;
    --content-padding-horizontal: 16px; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #template-8:not([class*='responsive-override']) {
    --background-size: contain;
    --default-pad: 40px;
    --title-font-size: 24px;
    --title-line-height: 32px;
    --subtitle-font-size: 16px;
    --container-width: 440px; } }

@media screen and (min-width: 640px) and (max-width: 1024px) {
  #template-8:not([class*='responsive-override']) {
    --background-size: contain;
    --default-pad: 40px;
    --title-font-size: 24px;
    --title-line-height: 32px;
    --subtitle-font-size: 16px;
    --container-width: 520px; } }

@media screen and (min-width: 1024px) and (max-width: 999999999px) {
  #template-8:not([class*='responsive-override']) {
    --background-size: contain;
    --default-pad: 40px;
    --title-font-size: 36px;
    --title-line-height: 46px;
    --subtitle-font-size: 18px;
    --container-width: 550px;
    --title-margin-bottom: 24px;
    --subtitle-margin-bottom: 40px; } }

#template-8.breakpoint-desktop {
  --container-width: 550px !important;
  --title-font-size: 36px !important;
  --title-line-height: 46px !important;
  --subtitle-font-size: 18px !important;
  --title-margin-bottom: 24px !important;
  --subtitle-margin-bottom: 40px !important; }

#template-8.likepopup-container {
  width: var(--container-width);
  background-color: #f5f5f5; }

#template-8 .template-container {
  --blue: #2962ff; }
  #template-8 .template-container #background-container {
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: var(--background-size); }
    #template-8 .template-container #background-container #close {
      right: -9px;
      top: -9px;
      display: inline-block; }
      #template-8 .template-container #background-container #close img {
        width: 30px;
        display: inline-block;
        border-radius: 50%; }
    #template-8 .template-container #background-container #content {
      padding: var(--default-pad) var(--content-padding-horizontal, var(--default-pad)) var(--default-pad); }
      #template-8 .template-container #background-container #content h2 {
        font-family: 'Montserrat', sans-serif;
        font-size: var(--title-font-size);
        font-weight: 700;
        line-height: var(--title-line-height);
        color: #2962ff; }
      #template-8 .template-container #background-container #content h3 {
        font-family: 'Montserrat', sans-serif;
        font-size: var(--subtitle-font-size);
        font-weight: 500;
        line-height: 24px;
        color: #4a4a4a;
        margin-top: 24px; }
    #template-8 .template-container #background-container #footer {
      position: relative;
      padding: 0 0 40px; }

#template-8 #promotion {
  position: absolute;
  left: 0;
  width: 100%;
  background: none;
  color: #eee;
  margin: 8px auto; }
  #template-8 #promotion a {
    color: inherit; }
    #template-8 #promotion a:hover {
      color: white; }

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
/**
This mixin provides overrides for each media query breakpoint provided by it's consumers.
usage:

* if th4 LikePopup component is passed a "responsive-override" prop, a specific breakpoint styles will be used for all screen sizes.

1. define a sass map describing the breakpoint-specific style overrides:
   use breakppoint names as keys.

   $overrides: (
      defaults: (
         size: 20px,
      ),
      mobile-small: (
         size: 10px,
      ),
      mobile-big: (
         primary-color: #234231;
      ),
      desktop: (
         size: 50px
      )
   );

2. provide this map to the mixin:

   @include map_to_media_queries($overrides);

3. use CSS variables based on the key names provided to the mixin:

   width: var(--size);
   border-color: var(--primary-color);
*/
/**
this mixin provides width and height, in a way that maintains a fixed aspect-ratio.

usage:
provide the mixin with a width, and aspect ratio in the format of: "width:height":

.screen {
   @include with-aspect-ratio(50px, "16:9");
}
*/
/**
this function returns a calculated length of a list of items,
based on the number of items ($n), the size of each item ($size) and the size of the gaps between the items ($gap)

usage:

.avatars-list {
   width: length-with-gaps(20, 16px, 4px);
}
*/
@media screen and (min-width: 0px) and (max-width: 480px) {
  #template-9:not([class*='responsive-override']) {
    --close-background-color: #f6f6f6;
    --image-size: 160px;
    --title-font-size: 24px;
    --title-line-height: 32px;
    --container-width: 280px; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #template-9:not([class*='responsive-override']) {
    --close-background-color: #f6f6f6;
    --image-size: 160px;
    --title-font-size: 26px;
    --title-line-height: 36px;
    --container-width: 320px; } }

@media screen and (min-width: 640px) and (max-width: 1024px) {
  #template-9:not([class*='responsive-override']) {
    --close-background-color: #f6f6f6;
    --image-size: 160px;
    --title-font-size: 26px;
    --title-line-height: 36px;
    --container-width: 320px; } }

@media screen and (min-width: 1024px) and (max-width: 999999999px) {
  #template-9:not([class*='responsive-override']) {
    --close-background-color: transparent;
    --image-size: 180px;
    --title-font-size: 28px;
    --title-line-height: 36px;
    --container-width: 375px; } }

#template-9.breakpoint-desktop {
  --container-width: 375px !important;
  --title-font-size: 28px !important;
  --image-size: 180px !important;
  --close-background-color: transparent !important; }

#template-9.likepopup-container {
  width: var(--container-width);
  background-color: #fefefe; }

#template-9 #close {
  background-color: var(--close-background-color);
  display: inline-block;
  --size: 40px;
  width: var(--size);
  height: var(--size);
  top: 0;
  right: 0; }
  #template-9 #close img {
    display: inline-block;
    border-radius: 50%;
    --offset: 16px;
    right: var(--offset);
    top: var(--offset); }

#template-9 #content {
  padding: 33px;
  padding-bottom: 46px; }
  #template-9 #content img {
    width: var(--image-size); }
  #template-9 #content h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: var(--title-font-size);
    font-weight: 600;
    line-height: var(--title-line-height);
    color: #4a4a4a;
    margin-top: 20px; }
  #template-9 #content h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #4a4a4a;
    margin-top: 20px; }

#template-9 #footer {
  height: 60px;
  background-color: #ebe9e9;
  position: relative; }

#template-9 #promotion {
  display: none; }
